import { default as _91_91endVerse_93_93NZXJyIxj6PMeta } from "/codebuild/output/src2829677374/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue?macro=true";
import { default as _91title_93IhHIcNaVNsMeta } from "/codebuild/output/src2829677374/src/biblia-frontend/src/pages/clanky/[title].vue?macro=true";
import { default as indexg9QtQi0lDLMeta } from "/codebuild/output/src2829677374/src/biblia-frontend/src/pages/index.vue?macro=true";
import { default as _91title_939Qlz3GLfbRMeta } from "/codebuild/output/src2829677374/src/biblia-frontend/src/pages/verse/[title].vue?macro=true";
import { default as vyhladavaniejaviS7UQVnMeta } from "/codebuild/output/src2829677374/src/biblia-frontend/src/pages/vyhladavanie.vue?macro=true";
export default [
  {
    name: _91_91endVerse_93_93NZXJyIxj6PMeta?.name ?? "citanie-translation-book-chapter-verse-endVerse",
    path: _91_91endVerse_93_93NZXJyIxj6PMeta?.path ?? "/citanie/:translation()/:book()/:chapter()/:verse?/:endVerse?",
    meta: _91_91endVerse_93_93NZXJyIxj6PMeta || {},
    alias: _91_91endVerse_93_93NZXJyIxj6PMeta?.alias || [],
    redirect: _91_91endVerse_93_93NZXJyIxj6PMeta?.redirect,
    component: () => import("/codebuild/output/src2829677374/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue").then(m => m.default || m)
  },
  {
    name: _91title_93IhHIcNaVNsMeta?.name ?? "clanky-title",
    path: _91title_93IhHIcNaVNsMeta?.path ?? "/clanky/:title()",
    meta: _91title_93IhHIcNaVNsMeta || {},
    alias: _91title_93IhHIcNaVNsMeta?.alias || [],
    redirect: _91title_93IhHIcNaVNsMeta?.redirect,
    component: () => import("/codebuild/output/src2829677374/src/biblia-frontend/src/pages/clanky/[title].vue").then(m => m.default || m)
  },
  {
    name: indexg9QtQi0lDLMeta?.name ?? "index",
    path: indexg9QtQi0lDLMeta?.path ?? "/",
    meta: indexg9QtQi0lDLMeta || {},
    alias: indexg9QtQi0lDLMeta?.alias || [],
    redirect: indexg9QtQi0lDLMeta?.redirect,
    component: () => import("/codebuild/output/src2829677374/src/biblia-frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91title_939Qlz3GLfbRMeta?.name ?? "verse-title",
    path: _91title_939Qlz3GLfbRMeta?.path ?? "/verse/:title()",
    meta: _91title_939Qlz3GLfbRMeta || {},
    alias: _91title_939Qlz3GLfbRMeta?.alias || [],
    redirect: _91title_939Qlz3GLfbRMeta?.redirect,
    component: () => import("/codebuild/output/src2829677374/src/biblia-frontend/src/pages/verse/[title].vue").then(m => m.default || m)
  },
  {
    name: vyhladavaniejaviS7UQVnMeta?.name ?? "vyhladavanie",
    path: vyhladavaniejaviS7UQVnMeta?.path ?? "/vyhladavanie",
    meta: vyhladavaniejaviS7UQVnMeta || {},
    alias: vyhladavaniejaviS7UQVnMeta?.alias || [],
    redirect: vyhladavaniejaviS7UQVnMeta?.redirect,
    component: () => import("/codebuild/output/src2829677374/src/biblia-frontend/src/pages/vyhladavanie.vue").then(m => m.default || m)
  }
]