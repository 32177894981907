import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#f0fdf4",
    "barButtonBackground": "#22c55e",
    "barButtonColor": "#ffffff",
    "barButtonHoverBackground": "#4ade80",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#000",
    "checkboxActiveBackground": "#22c55e",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#22c55e",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#333",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#22c55e",
    "controlButtonIconColor": "#22c55e",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#22c55e",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#4ade80",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "sk": "Informácia o vašich nastaveniach cookies na našej stránke sú uložené pod názvom \"ncc_c\" a \"ncc_e\" a majú platnosť 1 rok."
        },
        "id": "ncc_c",
        "name": {
          "sk": "Cookies"
        },
        "targetCookieIds": [
          "ncc_c",
          "ncc_e"
        ]
      },
      {
        "description": {
          "sk": "Informácia o vašich predvoľbách sú uložené pod názvom \"biblia_sk\" a majú platnosť 1 rok."
        },
        "id": "biblia_sk",
        "name": {
          "sk": "Vaše nastavenia"
        },
        "targetCookieIds": [
          "biblia_sk"
        ]
      }
    ],
    "optional": [
      {
        "description": {
          "sk": "Tieto cookies slúžia na zisťovanie anonymných údajov o návštevnosti tejto stránky. Sú uložené pod názvom \"_ga\", \"_gid\", a \"_gat_*\" a majú platnosť v rozpätí od jednéhé dňa až po 2 roky."
        },
        "id": "google",
        "name": {
          "sk": "Google Analytics"
        },
        "targetCookieIds": [
          "google"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/",
    "sameSite": "strict",
    "secure": true
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "sk",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "sk": {
      "accept": "Prijať",
      "acceptAll": "Prijať všetko",
      "bannerDescription": "Váš súhlas si pýtame v súvislosti s cookies, ktoré zabezpečujú štatistické dáta návštevnosti webu pre lepšie vyhodnocovanie pokračovania projektu Biblia.sk. Všetky ostatné cookies sú nevyhnutné pre fungovanie tejto webovej stránky.",
      "bannerTitle": "Cookies",
      "close": "Zatvoriť",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nevyhnutné  cookies",
      "cookiesOptional": "Voliteľné cookies",
      "decline": "Odmietnuť",
      "declineAll": "Odmietnuť všetky",
      "here": "tu",
      "iframeBlocked": "Ak ho chcete zobraziť, povoľte obslužné cookies",
      "manageCookies": "Ďalšie informácie a prispôsobenie",
      "save": "Uložiť",
      "settingsUnsaved": "Máte neuložené nastavenia"
    }
  }
} as ModuleOptions