import { defineStore } from 'pinia'
import { useTranslationsStore } from "@/store/translations"

export const useGlobalStore = defineStore({
    id: 'global',
    state: () => {
        return {
            texts: false,
            settings: {
                diacritics: false,
                caseSensitive: false,
                exactWord: false,
                wordOrdering: false,
                slowSearch: false,
                showSebDt: true,
                showChapterHeadings: true,
                showTitleHeadings: true,
                showVerses: true,
                showBibleNotes: false,
                showBibleReferences: false,
                showMyNotes: true,
                showVersePerLine: false,
                fontSize: 20,
                highlightVerse: true,
                preferredReadingTranslation: "seb",
                preferredCompareTranslations: ["ssv", "roh"],
                preferredSearchTranslations: ["seb"],
                showAudioPlayer: true,
                audioRate: 1.0,
                notice: true,
                donation: true,
                donationBar: true,
                noticeToolbar: true,
                whatsNew: false
            },
            sidebar: {
                value: false,
                name: "none"
            },
            loader: false,
            loadingParallels: {
                status: false,
                textItemId: false
            },
            selectedTranslation: false,
            selectedBook: false,
            selectedChapter: false,
            dropDown: false,
            lastLoadedChapter: false,
            parallels: [],
            activeVerses: [],
            errorVerse: false
        }
    },
    actions: {
        handleErrorVerse(verse){
            this.errorVerse = verse
        },
        handleActiveVerses(textItem = null){

            if(!textItem){
                this.activeVerses = [];
                return;
            }

            if (this.activeVerses.includes(textItem.id)) {
                this.activeVerses = this.activeVerses.filter(id => {
                    return id !== textItem.id;
                });
            }else {
                this.activeVerses.push(textItem.id)
            }

        },
        toggleDropdown(name){

            if(name == false){
                this.dropDown = false
            }else {
                this.dropDown = name
            }

        },
        async selectChapter(number){

            this.selectedChapter = number

        },
        async selectBook(identifier, restoreChapter = false){

            /*
            const route = useRoute();
            Sentry.setContext("character", {
                url: route.path
            });
            */

            // Knihu vyberie zo zoznamu kníh, ktoré sa nachdázajú pri daných prekladoch
            this.selectedBook = this.selectedTranslation.books.find(book => {
                return book.abbreviation == identifier;
            });

            if(this.selectedBook === undefined){

                this.selectedBook = this.selectedTranslation.books[0]
                this.selectedChapter = this.selectedBook.chapters[0]
                return navigateTo("/citanie/" + this.selectedTranslation.identifier + "/" + this.selectedBook.abbreviation + "/" + this.selectedChapter);

            }

            // pre knihu musí zvoliť aj kapitolu
            // if)   ak ešte nie je zadefinovaná, alebo ak sa má resetovať kvôli zmene knihy (nie prekladu napr.)
            //       potom vyberie prvú existujúcu kapitolu danej knihy
            // else) ak je zadefinovaná
            //      if)     a zároveň sa nachádza ako kapitola v danej knihe (napr. ak idem z knihy Sir, ktorá má kapitolu 0)
            //              do knihy, ktorá nemá kapitolu 0
            //      else)   ak sa teda nenachádza v zozname kapitol danej knihy, vyber prvú existujúcu kapitolu
            if(this.selectedChapter == false || restoreChapter){
                this.selectedChapter = this.selectedBook.chapters[0]
            }else {
                if(this.selectedBook.chapters.includes(Number(this.selectedChapter))){
                    this.selectedChapter = this.selectedChapter;
                }else {
                    this.selectedChapter = this.selectedBook.chapters[0]
                }
            }

        },
        async selectTranslation(identifier){

            const translationsStore = useTranslationsStore()

            this.selectedTranslation = translationsStore.translations.find(translation => {
                return translation.identifier == identifier;
            });

        },
        handleLoader(value){
            this.loader = value
        },
        async handleSaveSetting(type, value){

            if(type == "fontSize"){
                value = parseInt(value)
            }
            else if(type == "audioRate"){
                value = value
            }
            else if(type == "preferredReadingTranslation" || type == "preferredSearchTranslations" || type == "preferredCompareTranslations"){
                value = value
            }
            else if(value == undefined){
                value = !this.settings[type]
            }
            else {
                value = value === 0 || value === 1 || value === "0" || value === "1" ? Boolean(parseInt(value)) : Boolean(value)
            }

            this.settings[type] = value

        },
        handlePreferredTranslations(translations, identifier){

            if (translations.includes(identifier)) {
                translations = translations.filter(m => {
                    return m !== identifier;
                });
            }else {
                translations.push(identifier)
                translations.sort((a, b) => a - b)  
            }

            return translations;

        },
        toggleSidebar(name){
            this.sidebar.value = !this.sidebar.value
            this.sidebar.name = !this.sidebar ? "none" : name
        },
        clearTexts(){
            this.texts = {}
        },
        async fetchTexts(chapter = false) {

            const { data } = await useAsyncData('texts', () => $fetch('/api/chapter', {
                params: {
                    translation: this.selectedTranslation.identifier,
                    book: this.selectedBook.abbreviation,
                    chapter: chapter ? chapter : this.selectedChapter
                }
            }));

            /*
            const currentChapter = chapter ? chapter : this.selectedChapter
            const { data } = await useAsyncData('texts', () => $fetch('https://api2.biblia.sk/api/get-texts-resource/' + this.selectedTranslation.identifier + '/' + this.selectedBook.abbreviation + '/' + currentChapter + '?key=597133743677397A24432646294A404E635166546A576E5A7234753778214125'))
            */

            if(data._value && data._value.data.length){

                var textObject = Object.assign({}, this.texts, {})
                textObject[chapter ? chapter : this.selectedChapter] = data._value.data
                this.texts = textObject

            }

        },
        async fetchParallels(textItem){

            if(typeof this.texts[textItem.chapter] !== 'undefined'){

                const parallel = this.texts[textItem.chapter].find(item => {
                    return item.translation == textItem.translation &&
                    item.book == textItem.book &&
                    item.chapter == textItem.chapter &&
                    item.verse == textItem.verse;
                })["parallels"];

                if(parallel != undefined) return;

            }

            this.loadingParallels.status = true
            this.loadingParallels.textItemId = textItem.id

            const { data } = await useAsyncData('parallels', () => $fetch("/api/parallel", {
                params: {
                    translation: textItem.translation,
                    book: textItem.book,
                    chapter: textItem.chapter,
                    verse: textItem.verse
                }
            }));

            if(data._value && data._value.data.length){

                if(typeof this.texts[textItem.chapter] !== 'undefined'){
                    this.texts[textItem.chapter].find(item => {
                        return item.translation == textItem.translation &&
                        item.book == textItem.book &&
                        item.chapter == textItem.chapter &&
                        item.verse == textItem.verse;
                    })["parallels"] = data._value.data
                }

            }

            this.loadingParallels.status = false
            this.loadingParallels.textItemId = false

        }
    },
    getters: {
        getSettings: state => state.settings,
        getSidebar: state => state.sidebar,
        getSidebarType: state => state.sidebar.value ? state.sidebar.name : "none",
        getLoaderStatus: state => state.loader,
        getSelectedTranslation: state => state.selectedTranslation,
        getSelectedBook: state => state.selectedBook,
        getSelectedChapter: state => state.selectedChapter,
        getDropdown: state => state.dropDown,
        getTexts: state => state.texts,
        getActiveVerses: state => state.activeVerses,
        getErrorVerse: state => state.errorVerse,
        getLoadingParallels: state => state.loadingParallels,
        getLastLoadedChapter: state => Object.keys(state.getTexts)[Object.keys(state.getTexts).length-1]
    }
})